<template>
  <div class="content-wrapper">
    <editable-map
      class="editable-map"
      ref="map"
      :zoom="zoom"
      editable
      :center="center"
      @update:zoom="zoomUpdated"
    >
      <l-tile-layer
        :url="url"
        :options="{ maxZoom: 19 }"
      >
      </l-tile-layer>
      <l-polyline
        :lat-lngs="getCoordinateRoute"
        :weight="3"
        :color="colorRoute"
      />
      <template
        v-if="vectors"
      >
        <editable-polyline
          v-for="(edge, index) in vectorsBusRoute"
          :key="index"
          :lat-lngs="edge.geometry"
          :editable="edge.edit"
          @click="editPolyline(edge, $event)"
          color="#4d2125"
          :weight="3"
        >
        </editable-polyline>
      </template>

      <template>
        <editable-polyline
          :lat-lngs="geometryCoordinatePolyline"
          color="#4d2125"
          editable
          :weight="3"
        >
        </editable-polyline>
      </template>

      <template v-if="showGeometryOnRoute">
        <editable-polyline
          v-for="(edge, index) in uniteNodesAndVectors"
          :key="index + 'edge geometry'"
          :lat-lngs="edge.geometry"
          :editable="edge.edit"
          color="#000"
          @click="editPolyline(edge, $event)"
          :weight="3"
        >
        </editable-polyline>
      </template>

      <l-marker
        v-for="(marker, index) in selectedStationsOnRoute"
        class="l-marker"
        :key="index + 'station'"
        :lat-lng="[marker.coord.lat, marker.coord.lng]"
      >
        <l-icon
          :icon-anchor="iconAnchor"
          :icon-url="iconActive"
          :icon-size="iconSize"
        >
        </l-icon>
      </l-marker>

      <template v-if="showFakeMarker">
        <l-marker
          v-for="(markerFake, index) in nodesFake"
          :key="index + 'fake'"
          :draggable="true"
          :lat-lng="[markerFake.coord.lat, markerFake.coord.lng]"
          @click="selectNode(markerFake)"
          @update:lat-lng="updateCoordinate"
        >
          <l-icon
            :icon-anchor="markerFake.id === selectedNode.id ? iconAnchorActive : iconAnchor"
            :icon-size="markerFake.id === selectedNode.id ? iconSizeActive : iconSize"
            :icon-url="markerFake.id === selectedNode.id ? iconActive : iconFake"
          >
          </l-icon>
        </l-marker>
      </template>

      <template v-if="showRealMarker">
        <l-marker
          v-for="(markerReal, index) in nodesReal"
          :key="index + 'real'"
          :draggable="true"
          :lat-lng="[markerReal.coord.lat, markerReal.coord.lng]"
          @click="selectNode(markerReal)"
          @update:lat-lng="updateCoordinate"
        >
          <l-icon
            :icon-anchor="markerReal.id === selectedNode.id ? iconAnchorActive : iconAnchor"
            :icon-size="markerReal.id === selectedNode.id ? iconSizeActive : iconSize"
            :icon-url="markerReal.id === selectedNode.id ? iconActive : iconReal"
          >
          </l-icon>
        </l-marker>
      </template>

      <template v-if="showNodeOnRoute">
        <l-marker
          v-for="(marker, index) in nodeOnRoute"
          :key="index + 'real + fake'"
          :draggable="true"
          @click="selectNode(marker)"
          @update:lat-lng="updateCoordinate"
          :lat-lng="[marker.coord.lat, marker.coord.lng]"
        >
          <l-icon
            :icon-anchor="iconAnchor"
            :icon-url="marker.type === 'FAKE' ? iconFake : iconReal"
          >
          </l-icon>
        </l-marker>
      </template>
    </editable-map>

    <div class="content-editor">
      <ul class="tabs">
        <li
          v-for="(tab, index) in tabs"
          :key="index"
          :class="[activeTab === tab.id ? 'activeTab' : '']"
          @click="activeTab = tab.id"
        >
          {{ tab.title }}
        </li>
      </ul>
      <div class="editor">
        <div 
          v-show="activeTab === 'node_and_edge'" 
          class="editor-graph"
        >
          <div class="block">
            <div
              v-if="NODES.length > 0"
              class="checkbox-block"
            >
              <div class="checkbox">
                <input type="checkbox" id="show-fake-marker" v-model="showFakeMarker" />
                <label for="show-fake-marker">Показать "фейковые" остановки</label>
              </div>
              <div class="checkbox">
                <input type="checkbox" id="show-real-marker" v-model="showRealMarker" />
                <label for="show-real-marker">Показать реальные остановки</label>
              </div>
            </div>
          </div>
          <div class="block">
            <button
              type="button"
              class="button button--select"
              @click="getNodes"
            >
              Загрузить узлы
            </button>
            <button
              type="button"
              class="button button--delete"
              @click="deleteNodes"
            >
              Удалить узлы
            </button>
            <button
              type="button"
              class="button button--select"
              @click="showVectors"
            >
              <template
                v-if="!vectors"
              >
                Показать ребра
              </template>
              <template
                v-else
              >
                Скрыть ребра
              </template>
            </button>
          </div>
        </div>
        <div
          v-show="activeTab === 'node'"
          class="editor-nodes"
        >
          <div class="block">
            <multiselect
              v-model="selectedTypeNode"
              :options="typeNode"
              label="type"
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              placeholder="Выбрать тип узла"
            >
            </multiselect>
          </div>
          <div class="block block-input">
            <input
              class="primary-input"
              v-model.trim="latitude"
              id="latitude"
              placeholder="Широта"
              type="number"
            >
          </div>
          <div class="block block-input">
            <input
              class="primary-input"
              v-model.trim="longitude"
              id="longitude"
              placeholder="Долгота"
              type="number"
            >
          </div>
          <div class="block">
            <multiselect
              v-model="selectedStation"
              :options="STATIONS"
              :custom-label="nameWithId"
              placeholder="Остановка"
              label="name"
              track-by="name"
              selectLabel=""
              deselectLabel=""
            >
              <span slot="noResult">
                Елементы не найдены
              </span>
            </multiselect>
          </div>
          <div
            v-if="isSelectedTypeNode"
            class="block"
          >
            <multiselect
              v-model="selectedRoute"
              :options="ROUTES"
              :custom-label="noWithId"
              placeholder="Маршрут"
              label="no"
              track-by="no"
              selectLabel=""
              deselectLabel=""
            >
              <span slot="noResult">
                Елементы не найдены
              </span>
            </multiselect>
          </div>
          <div class="control-btn">
            <button 
              class="button button--save"
              type="button"
              @click="addOrChangeNode"
            >
              Сохранить
            </button>
            <button
              class="button button--cancel"
              type="button"
              @click="cancelNode"
            >
              Отменить
            </button>
            <button
              class="button button--delete"
              type="button"
              @click="deleteNode"
            >
              Удалить
            </button>
          </div>
        </div>
        <div
          v-show="activeTab === 'edge'"
          class="editor-edge"
        >
          <div class="block">
            <input
              type="text"
              v-model="editedPointA.id"
              placeholder="Точка А"
              class="primary-input"
            />
            <img
              @click="clearPoint('pointA')"
              class="close-img"
              src="@/assets/img/close.svg"
            >
          </div>
          <div class="block">
            <input
              type="text"
              v-model="editedPointB.id"
              placeholder="Точка B"
              class="primary-input"
            />
            <img
              @click="clearPoint('pointB')"
              class="close-img"
              src="@/assets/img/close.svg"
            >
          </div>
          <div class="block">
            <multiselect
              v-model="selectedTypeEdge"
              :options="typeEdge"
              label="type"
              selectLabel=""
              deselectLabel=""
              :searchable="false"
              :close-on-select="false"
              :show-labels="false"
              placeholder="Выбрать тип ребра"
            >
            </multiselect>
          </div>
          <div class="block">
            <input
              class="primary-input"
              v-model.trim="weightEdge"
              type="number"
              placeholder="Вес ребра"
            >
          </div>
          <div class="control-btn">
            <button
              class="button button--save"
              type="button"
              @click="addOrChangeEdge"
            >
              Сохранить
            </button>
            <button
              class="button button--cancel"
              type="button"
              @click="cancelEdge"
            >
              Отменить
            </button>
            <button
              class="button button--delete"
              type="button"
              @click="deleteEdge"
            >
              Удалить
            </button>
          </div>
          <div class="block">
            <div
              v-show="selectedRoutes.length > 0"
              class="block-checkbox"
            >
              <div class="checkbox">
                <input
                  name="route"
                  type="radio"
                  id="show-direct-route"
                  value="direct"
                  v-model="showRoute"
                />
                <label for="show-direct-route">
                  Показать только прямые маршруты
                </label>
              </div>
              <div class="checkbox">
                <input
                  name="route"
                  type="radio"
                  id="show-backward-route"
                  value="backward"
                  v-model="showRoute"
                />
                <label for="show-backward-route">
                  Показать только обратные маршруты
                </label>
              </div>
              <div class="checkbox">
                <input
                  name="route"
                  type="radio"
                  id="show-direct-backward-route"
                  value="directAndBackward"
                  v-model="showRoute"
                />
                <label for="show-direct-backward-route">
                  Показать прямой и обратный маршруты
                </label>
              </div>
            </div>
            <multiselect 
              v-model="selectedRoutes"
              tag-placeholder="Add this as new tag"
              placeholder="Маршруты"
              selectLabel=""
              deselectLabel=""
              label="no"
              track-by="id"
              :custom-label="noWithId"
              :options="ROUTES"
              :multiple="true"
              :taggable="true"
              @select="selectRoute"
              @remove="removeRoute"
            />
          </div>
          <div class="button-block">
            <button 
              class="button button--select"
              type="button" 
              @click="selectAllObject('route')"
            >
              Выбрать все маршруты
            </button>
            <button 
              class="button button--delete"
              type="button"
              @click="deleteAllObject('route')"
            >
              Удалить все маршруты
            </button>
          </div>
          <div class="block">
            <multiselect 
              v-model="selectedStations"
              tag-placeholder="Add this as new tag"
              placeholder="Остановки"
              selectLabel=""
              deselectLabel=""
              label="name"
              track-by="id"
              :custom-label="nameWithId"
              :options="STATIONS"
              :multiple="true"
              :taggable="true"
            />
          </div>
          <div class="button-block">
            <button
              v-show="selectedRoutes.length > 0"
              class="button button--select"
              type="button"
              @click="getStopOnRoute"
            >
              Показать ОСТАНОВКИ на выбранном маршруте
            </button>
            <button
              v-show="selectedRoutes.length > 0"
              class="button button--select"
              type="button"
              @click="getNodeOnRoute"
            >
              <template v-if="showNodeOnRoute">
                Скрыть УЗЛЫ на выбранном маршруте
              </template>
              <template v-else>
                Показать УЗЛЫ на выбранном маршруте
              </template>
            </button>  
            <button
              v-show="selectedRoutes.length > 0"
              class="button button--select"
              type="button"
              @click="getGeometryOnRoute"
            >
              <template v-if="showGeometryOnRoute">
                Скрыть РЕБРА на выбранном маршруте
              </template>
              <template v-else>
                Показать РЕБРА на выбранном маршруте
              </template>
            </button>
            <button 
              class="button button--select"
              type="button"
              @click="selectAllObject('station')"
            >
              Выбрать все остановки
            </button>
            <button
              class="button button--delete"
              type="button"
              @click="deleteAllObject('station')"
            >
              Удалить все остановки
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import L from "leaflet";
import LDraw from 'leaflet-draw';
import Multiselect from 'vue-multiselect';
import { EditableMap, EditablePolyline } from "vue2-leaflet-editable";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    Multiselect,
    EditableMap,
    EditablePolyline
  },
  data() {
    return {
      activeTab: "node_and_edge",
      center: [61.0042, 69.0019],
      geometryPolyline: [],
      iconSize: [25, 41],
      iconSizeActive: [45, 81],
      iconReal: require("@/assets/img/point-real.svg"),
      iconFake: require("@/assets/img/point-fake.svg"),
      iconActive: require("@/assets/img/point-default.svg"),
      iconAnchor: [11, 31],
      iconAnchorActive: [20, 58],
      latitude: null,
      longitude: null,
      map: null,
      pointA: null,
      pointB: null,
      showRoute: "",
      showDirectRoute: false,
      showBackwardRoute: false,
      showDirectAndBackwardRoute: false,
      showFakeMarker: false,
      showRealMarker: false,
      selectedStation: null,
      selectedRoute: null,
      selectedNode: {},
      selectedTypeNode: null,
      selectedTypeEdge: null,
      selectEdge: {},
      selectedRoutes: [],
      selectedStations: [],
      showNodeOnRoute: false,
      showGeometryOnRoute: false,
      tabs: [
        {
          title: "Ребра / Узлы",
          id: "node_and_edge"
        },
        {
          title: "Редактирование узла",
          id: "node"
        },
        {
          title: "Редактирование ребра",
          id: "edge"
        }
      ],
      typeNode: [
        {
          type: "REAL",
        },
        {
          type: "FAKE",
        },
      ],
      typeEdge: [
        {
          type: "DOWNLOAD",
        },
        {
          type: "UNLOAD",
        },
        {
          type: "BUS_ROUTE",
        }
      ],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      vectors: false,
      weightEdge: null,
      zoom: 12,
    }
  },

  mounted() {
    this.GET_STATIONS();
    this.GET_ROUTES();
    this.GET_VECTORS();

    this.$nextTick(() => {
      const vm = this;
      const map = this.$refs.map.mapObject;
      const editableLayers = new L.FeatureGroup().addTo(map);

      map.addLayer(editableLayers);

      const options = {
        position: 'topleft',
        draw: {
          polygon: false,
          polyline: false,
          circlemarker: false,
          circle: false,
          marker: true,
          rectangle: false,
        },

        edit: {
          featureGroup: editableLayers
        }
      };

      const drawControl = new L.Control.Draw(options);
      map.addControl(drawControl);

      map.addLayer(editableLayers);

      map.on(L.Draw.Event.CREATED, function(e) {
        let type = e.layerType;
        let layer = e.layer;
        editableLayers.addLayer(layer);

        // Получить координаты точек (только для линии)
        if (type === "polyline") {
          let geometryPolyline = e.layer.getLatLngs();
          vm.geometryPolyline = geometryPolyline;
        }

        if (type === "marker") {
          let coord = layer.getLatLng();
          vm.latitude = coord.lat;
          vm.longitude = coord.lng;
          vm.activeTab = "node";
        }
      });
    });
  },

  methods: {
    ...mapActions([
      "GET_STATIONS",
      "GET_ROUTES",
      "GET_VECTORS",
      "GET_NODES",
      "GET_LIST_ROUTES",
      "ADD_OR_CHANGE_NODE",
      "ADD_OR_CHANGE_EDGE",
      "DELETE_NODE",
      "DELETE_VECTOR",
      "GET_STOP_ON_ROUTES"
    ]),

    ...mapMutations([
      "SET_CLEAR_ROUTES_LIST",
      "REMOVE_ITEM_COORDINATE_ROUTE",
      "REMOVE_ALL_COORDINATE_ROUTE",
      "REMOVE_NODES",
      "REMOVE_VECTORS",
      "REMOVE_STATIONS"
    ]),

    nameWithId({name, id}) {
      return `${name} [${id}]`;
    },

    noWithId({no, id}) {
      return `${no} [${id}]`;
    },

    async selectAllObject(prop) {
      const direction = 0;
      const backward = 1;

      if (prop === "route") {
        this.selectedRoutes = [...this.ROUTES];
        this.selectedRoutes.forEach(el => {
          this.GET_LIST_ROUTES({routeId: el.id, direction: direction, backward: backward});
        });
      } else {
        await this.GET_STATIONS();
        this.selectedStations = [...this.STATIONS];
      }
    },

    deleteAllObject(prop) {
      if (prop === "route") {
        this.selectedRoutes = [];
        this.REMOVE_ALL_COORDINATE_ROUTE();
      } else {
        this.REMOVE_STATIONS();
        this.selectedStations = [];
        this.GET_STATIONS();
      }
    },

    selectRoute(route) {
      const direction = 0;
      const backward = 1;

      this.GET_LIST_ROUTES({routeId: route.id, direction: direction, backward: backward});
    },

    removeRoute(route) {
      this.COORDINATE_ROUTE.forEach((el, index) => {
        if (el.routeId === route.id) {
          this.REMOVE_ITEM_COORDINATE_ROUTE({index: index});
        }
      });
    },

    getNodes() {
      this.GET_NODES();
    },

    showVectors() {
      this.vectors = !this.vectors;
      this.GET_VECTORS();
    },

    deleteNodes() {
      this.REMOVE_NODES();
    },

    deleteVectors() {
      this.REMOVE_VECTORS();
    },

    selectNode(node) {
      if (this.activeTab === "edge") {
        if (this.pointA && this.pointB) return;

        if (!this.pointA) {
          this.pointA = node;
          return;
        };

        if (this.pointA) {
          this.pointB = node;
          return;
        };
      } else {
        this.activeTab = "node";
      }

      if (this.activeTab === "node") {
        this.selectedNode = node;
        this.latitude = node.coord.lat;
        this.longitude = node.coord.lng;
        this.selectedStation = this.STATIONS.find(el => el.id === node.stopeId);
        this.selectedRoute = this.ROUTES.find(el => el.id === node.routeId);
        this.selectedTypeNode = { ...this.typeNode.find(el => el.type === node.type)};
      }
    },

    addOrChangeNode() {
      this.ADD_OR_CHANGE_NODE({
        id: this.selectedNode.id,
        type: this.selectedTypeNode.type,
        route_id: this.selectedRoute ? this.selectedRoute.id : null,
        stop_id: this.selectedStation.id,
        coord: {
          lat: this.latitude,
          lng: this.longitude
        }
      })
    },

    addOrChangeEdge() {
      let geometryPolyline = this.geometryPolyline.map(el => {
        return {
          lat: el.lat,
          lng: el.lng
        }
      });

      this.ADD_OR_CHANGE_EDGE({
        id: this.selectEdge.id,
        type: this.selectedTypeEdge.type,
        start_node_id: this.selectEdge.start_node_id || this.pointA.id,
        end_node_id: this.selectEdge.end_node_id || this.pointB.id,
        geometry: geometryPolyline.length > 0 ? JSON.stringify(geometryPolyline) : JSON.stringify(this.geometryCoordinatePolyline),
        weight: this.weightEdge,
      });
    },

    cancelNode() {
      this.latitude = null;
      this.longitude = null;
      this.selectedTypeNode = null;
      this.selectedStation = null;
      this.selectedRoute = null;
      this.selectedNode = {};
      this.GET_NODES();
    },

    cancelEdge() {
      this.selectedTypeEdge = null;
      this.weightEdge = null;
      this.selectEdge.edit = false;
      this.selectEdge = {};
      this.geometryPolyline = [];
      this.pointA = null;
      this.pointB = null;
      this.GET_VECTORS();
    },

    deleteNode() {
      this.DELETE_NODE(this.selectedNode.id);
    },

    deleteEdge() {
      this.DELETE_VECTOR(this.selectEdge.id);
      this.cancelEdge();
    },

    updateCoordinate(coord) {
      this.latitude = coord.lat;
      this.longitude = coord.lng;
      this.selectedNode.coord = coord;
    },

    zoomUpdated(zoom) {
      console.log(zoom, 'zoom');
    },

    editPolyline(edge, e) {
      this.activeTab = "edge";
      this.geometryPolyline = e.target.getLatLngs();
      this.selectEdge = edge;
      edge.edit = !edge.edit;
      this.weightEdge = edge.weight;
      this.selectedTypeEdge = { ...this.typeEdge.find(el => el.type === edge.type_edge)};
      this.pointA = {
        id: edge.start_node_id,
      };
      this.pointB = {
        id: edge.end_node_id,
      };
    },

    async getStopOnRoute() {
      if (!this.isCheckNodes()) return;

      const ids = this.selectedRoutes.map(el => {
        return el.id;
      });
      await this.GET_STOP_ON_ROUTES(ids.join());
      this.selectedStations = [...this.STATIONS];
    },

    getNodeOnRoute() {
      if (!this.isCheckNodes()) return;

      this.showNodeOnRoute = !this.showNodeOnRoute;
    },

    getGeometryOnRoute() {
      this.showGeometryOnRoute = !this.showGeometryOnRoute;
    },

    clearPoint(point) {
      if (point === "pointA") {
        this.pointA = null;
        return;
      };

      if (point === "pointB") {
        this.pointB = null;
        return;
      };
    },

    isCheckNodes() {
      return this.NODES.length <= 0 ? alert("Загрузите узлы") : true
    },

    returnSortArr(arr) {
      if (this.showRoute === "direct") {
        return arr.filter(el => el.direction === Number(0))
      } else if (this.showRoute === "backward") {
        return arr.filter(el => el.direction === 1)
      } else {
        return arr;
      };
    }
  },

  computed: {
    ...mapGetters([
      "STATIONS",
      "ROUTES",
      "COORDINATE_ROUTE",
      "NODES",
      "VECTORS"
    ]),

    getCoordinateRoute: {
      get() {
        if (this.showRoute === "directAndBackward") {
          return this.COORDINATE_ROUTE.map(el => {
            let direct = el.coordinateRoutes.direct.map(item => [item.Lat, item.Lon]);
            let backward = el.coordinateRoutes.backward.map(item => [item.Lat, item.Lon]);
            return [...direct, ...backward];
          });
        };

        if (this.showRoute === "direct") {
          return this.COORDINATE_ROUTE.map(el => {
            return el.coordinateRoutes.direct.map(item => [item.Lat, item.Lon]);
          });
        };

        if (this.showRoute === "backward") {
          return this.COORDINATE_ROUTE.map(el => {
            return el.coordinateRoutes.backward.map(item => [item.Lat, item.Lon]);
          });
        };

        if (!this.showDirectAndBackwardRoute && !this.showDirectRoute && !this.showBackwardRoute) {
          return [];
        };
      },

      set(newValue) {
        return newValue;
      }
    },

    colorRoute() {
      return (this.showRoute === "directAndBackward") ? "#007bff" : 
        (this.showRoute === "direct") ? "#28a745" : 
        (this.showRoute === "backward") ? "#dc3545" : 
        ""
    },

    isSelectedTypeNode() {
      if (!this.selectedTypeNode) return

      return this.selectedTypeNode.type === "FAKE";
    },

    nodesFake() {
      return this.NODES.filter(el => el.type === "FAKE");
    },

    nodesReal() {
      return this.NODES.filter(el => el.type === "REAL");
    },

    vectorsDownload() {
      return this.VECTORS.filter(el => el.type === "DOWNLOAD");
    },

    vectorsUnload() {
      return this.VECTORS.filter(el => el.type === "UNLOAD");
    },

    vectorsBusRoute() {
      let busRoute = this.VECTORS.filter(el => el.type === "BUS_ROUTE");
      return busRoute.map(el => {
        return {
          end_node_id: el.end_node_id,
          geometry: JSON.parse(el.geometry),
          id: el.id,
          start_node_id: el.start_node_id,
          weight: el.weight,
          edit: false,
          type: "BUS_ROUTE"
        };
      });
    },

    editedPointA() {
      if (Object.keys(this.selectEdge).length !== 0) {
        return {
          id: this.pointA ? this.pointA.id : "",
        }
      } else {
        return {
          id: this.pointA ? this.pointA.id : "",
          coord: this.pointA ? { lat: this.pointA.coord.lat, lng: this.pointA.coord.lng } : ""
        }
      }
    },

    editedPointB() {
      if (Object.keys(this.selectEdge).length !== 0) {
        return {
          id: this.pointB ? this.pointB.id : "",
        }
      } else {
        return {
          id: this.pointB ? this.pointB.id : "",
          coord: this.pointB ? { lat: this.pointB.coord.lat, lng: this.pointB.coord.lng } : ""
        }
      }
    },

    geometryCoordinatePolyline() {
      const selectEdgeLength = Object.keys(this.selectEdge).length;

      if ((selectEdgeLength === 0) && this.pointA && this.pointB) {
        return [...this.pointA.coord, this.pointB.coord];
      } else {
        return []
      }
    },

    nodeOnRoute() {
      let nodeIdArr = this.selectedRoutes.map(el => {
        return el.id;
      });
      const nodes = this.NODES.filter(el => {
        return nodeIdArr.includes(el.routeId);
      });

      return this.returnSortArr(nodes);
    },

    uniteNodesAndVectors() {
      let nodeIdArr = this.selectedRoutes.map(el => {
        return el.id;
      });

      let arr = [];

      this.NODES.filter(el => {
        this.VECTORS.filter(item => {
          if (el.id === item.start_node_id) {
            arr.push({
              id: el.id,
              coord: el.coord,
              geometry: JSON.parse(item.geometry),
              start_node_id: item.start_node_id,
              end_node_id: item.end_node_id,
              routeId: el.routeId,
              type: el.type,
              edit: false,
              type_edge: item.type,
              weight: item.weight,
              direction: el.direction
            })
          }
        })
      });

      let routeArray = arr.filter(el => {
        if (el.geometry) {
          return nodeIdArr.includes(el.routeId);
        };
      });

      return this.returnSortArr(routeArray);
    },

    selectedStationsOnRoute() {
      let arr = [];

      this.nodeOnRoute.filter(el => {
        this.selectedStations.filter(item => {
          if (el.stopeId === item.id) {
            arr.push({
              id: el.id,
              coord: {
                lat: item.lat,
                lng: item.lon
              },
              direction: el.direction
            });
          }
        });
      });

      return this.returnSortArr(arr);
    },
  }
}
</script>

<style lang="scss" scoped>

.editable-map {
  position: relative;
  z-index: 1;
  height: 100vh; 
  width: 100%;
}

.icon {
  width: 20px;
}

.l-marker {
  color: red;
}

.tabs {
  display: flex;
  padding: 0;
  margin: 8px;

  li {
    padding: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #888;
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid #007bff;
    border-right: none;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    cursor: pointer;
    list-style-type: none;

    &:last-child {
      border-right: 1px solid #007bff;
    }
  }


  .activeTab {
    color: #000;
    background: transparent;
    border-bottom: transparent;
  }
}

.primary-input {
  min-height: 40px;
  display: block;
  padding: 0px 15px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;

  &::placeholder {
    color: #000;
  }
}

.button {
  padding: 10px;
  margin: 0 0 10px 0;
  border-radius: 10px;
  outline: none;
  border: none;
  color: white;

  &--select {
    background-color: #007bff;

    &:hover {
      background-color: #0069d9;
    }
  }

  &--delete {
    background-color: #dc3545;

    &:hover {
      background-color: #c82333;
    }
  }

  &--save {
    background-color: #28a745;

    &:hover {
      background-color: #218838;
    }
  }

  &--cancel {
    background-color: #6c757d;

    &:hover {
      background-color: #5a6268;
    }
  }

  &:hover {
    transition: 0.35s;
  }
}

.block {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .checkbox {
    label {
      padding-left: 8px;
    }
  }

  .close-img {
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 10px;
    transform: translate(0, -50%);

    &:hover {
      cursor: pointer;
    }
  }
}

.button-block {
  display: flex;
  flex-direction: column;
}

.block-input {
  box-sizing: content-box;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
}

.control-btn {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  button {
    width: 33%;
  }
}

.content-wrapper {
  display: flex;

  .content-editor {
    position: relative;
    z-index: 2;
    height: 100vh;
    box-shadow: -8px -2px 9px -5px #000;
  }

  .editor {
    display: flex;
    flex-direction: column;
    min-width: 380px;
    max-width: 380px;
    margin: 8px;
    overflow-y: scroll;

    .editor-graph, .editor-edge {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 77px);
    }

    .title {
      margin: 0 0 10px 0;
    }
  }
}


</style>