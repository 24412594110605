import Vue from "vue";
import VueRouter from "vue-router";

import Graph from "@/views/Graph";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Graph,
    name: Graph
  }
];

const router = new VueRouter({
  mode: "",
  // base: process.env.BASE_URL,
  routes
});

export default router;
