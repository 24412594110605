import Vue from "vue";
import Vuex from "vuex";
import RoutesAndBuses from "./modules/RoutesAndBuses";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {

  },
  getters: {
  },
  mutations: {
  },
  actions: {},
  modules: {
    RoutesAndBuses
  }
});
