import axios from "axios";

const state = {
  stations: [],
  routes: [],
  vectors: [],
  nodes: [],
  coordinateRoutes: []
}

const actions = {
  GET_STATIONS: async ({commit}) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/stops/${process.env.VUE_APP_TEMPORY_CAP_REGION_ID}`);
    commit("SET_STATION", data.data.stops);
  },

  GET_ROUTES: async ({commit}) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/routes`);
    commit("SET_ROUTES", data.data);
  },

  GET_VECTORS: async ({commit}) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/vectors`);
    commit("SET_VECTORS", data);
  },

  GET_NODES: async ({commit}) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/nodes`);
    commit("SET_NODES", data);
  },

  GET_LIST_ROUTES: async ({commit}, payload) => {
    let direct = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track/${payload.routeId}/${payload.direction}`);
    let backward = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track/${payload.routeId}/${payload.backward}`);
    commit("SET_LIST_ROUTES", { coordinateRoutes: { direct: direct.data.data, backward: backward.data.data }, routeId: payload.routeId })
  },

  GET_LIST_ROUTES_WITH_DIRECTION_DIRECT: async ({commit}, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track/${payload.routeId}/${payload.direction}`);
    commit("SET_LIST_ROUTES_WITH_DIRECTION_DIRECT", {data: data.data, routeId: payload.routeId});
  },

  GET_LIST_ROUTES_WITH_DIRECTION_BACKWARD: async ({commit}, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/track/${payload.routeId}/${payload.direction}`);
    commit("SET_LIST_ROUTES_WITH_DIRECTION_BACKWARD", {data: data.data, routeId: payload.routeId});
  },

  ADD_OR_CHANGE_NODE: async ({dispatch}, payload) => {
    const formData = new FormData();
    if (payload.id) {
      formData.append("id", payload.id);
      if (payload.route_id) {
        formData.append("route_id", payload.route_id);
      }
    }
    formData.append("stop_id", payload.stop_id);
    formData.append("type", payload.type);
    formData.append("coord", JSON.stringify(payload.coord));

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    if (!payload.id) {
      await axios.post(`${process.env.VUE_APP_TRANSPORT_URL}/node`, formData, config)
        .then(response => {
          console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;', response);
        })
        .catch(error => {
          console.log('%c%s', 'color: red; font: 1.2rem/1 Tahoma;', error);
        })
      await dispatch("GET_NODES");
      return
    }

    await axios.post(`${process.env.VUE_APP_TRANSPORT_URL}/node/${payload.id}`, formData, config)
      .then(response => {
        console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;', response.data.status);
      })
      .catch(error => {
        console.log('%c%s', 'color: red; font: 1.2rem/1 Tahoma;', error);
      })
    await dispatch("GET_NODES");
    return
  },

  ADD_OR_CHANGE_EDGE: async ({dispatch}, payload) => {
    const formData = new FormData();

    formData.append("id", payload.id);
    formData.append("type", payload.type);
    formData.append("weight", payload.weight);
    formData.append("start_node_id", payload.start_node_id);
    formData.append("end_node_id", payload.end_node_id);
    formData.append("geometry", payload.geometry);


    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    if (!payload.id) {
      await axios.post(`${process.env.VUE_APP_TRANSPORT_URL}/vector`, formData, config)
        .then(response => {
          console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;', response.data.status);
        })
        .catch(error => {
          console.log('%c%s', 'color: red; font: 1.2rem/1 Tahoma;', error);
        })
      await dispatch("GET_VECTORS");
      return
    }

    await axios.post(`${process.env.VUE_APP_TRANSPORT_URL}/vector/${payload.id}`, formData, config)
      .then(response => {
        console.log('%c%s', 'color: green; font: 1.2rem/1 Tahoma;', response.data.data.status);
      })
      .catch(error => {
        console.log('%c%s', 'color: red; font: 1.2rem/1 Tahoma;', error);
      })
    await dispatch("GET_VECTORS");
    return
  },

  DELETE_NODE: async ({dispatch}, payload) => {
    await axios.post(`${process.env.VUE_APP_TRANSPORT_URL}/node/delete/${payload}`)
      .then(response => {
        if (response.data.status === "ERROR") {
          console.log('%c%s', 'color: red; font-size: 14px;', response.data.status);
          console.log('%c%s', 'color: red; font-size: 14px;', response.data.description);
        } else {
          console.log('%c%s', 'color: green; font-size: 14px;', response.data.status);
        }
      })
      .catch(error => {
        console.log('%c%s', 'color: red; font-size: 14px;', error.description);
      })

    dispatch("GET_NODES");
  },

  DELETE_VECTOR: async ({dispatch}, payload) => {
    if (!payload) return;
    await axios.post(`${process.env.VUE_APP_TRANSPORT_URL}/vector/delete/${payload}`)
      .then(response => {
        if (response.data.status === "ERROR") {
          console.log('%c%s', 'color: red; font-size: 14px;', response.data.status);
          console.log('%c%s', 'color: red; font-size: 14px;', response.data.description);
        } else {
          console.log('%c%s', 'color: green; font-size: 14px;', response.data.status);
        }
      })
      .catch(error => {
        console.log('%c%s', 'color: red; font-size: 14px;', error.description);
      })

    dispatch("GET_VECTORS");
  },

  GET_STOP_ON_ROUTES: async ({commit}, payload) => {
    let { data } = await axios.get(`${process.env.VUE_APP_TRANSPORT_URL}/stops/${process.env.VUE_APP_TEMPORY_CAP_REGION_ID}?routes=${payload}`);
    commit("SET_STOP_ON_ROUTES", data.data.stops);
  }
}

const getters = {
  STATIONS: state => {
    return state.stations;
  },

  ROUTES: state => {
    return state.routes;
  },

  COORDINATE_ROUTE: state => {
    return state.coordinateRoutes;
  },

  NODES: state => {
    const arr = state.nodes.map(el => {
      let coord = JSON.parse(el.coord);
      return {
        coord: coord,
        type: el.type,
        id: el.id,
        routeId: el.route_id,
        stopeId: el.stop_id,
        direction: el.direction
      }
    })
    return arr;
  },

  VECTORS: state => {
    return state.vectors;
  }
}

const mutations = {
  SET_STATION: (state, payload) => {
    state.stations = payload;
  },
  
  SET_ROUTES: (state, payload) => {
    state.routes = payload;
  },

  SET_VECTORS: (state, payload) => {
    state.vectors = payload;
  },

  SET_NODES: (state, payload) => {
    state.nodes = payload;
  },

  SET_LIST_ROUTES_WITH_DIRECTION_DIRECT: (state, payload) => {
    state.coordinateRoutes.directRoute.push(payload);
  },

  SET_LIST_ROUTES_WITH_DIRECTION_BACKWARD: (state, payload) => {
    state.coordinateRoutes.backwardRoute.push(payload);
  },

  SET_LIST_ROUTES: (state, payload) => {
    state.coordinateRoutes.push(payload);
  },

  SET_CLEAR_ROUTES_LIST: (state) => {
    state.coordinateRoutes = [];
  },

  REMOVE_ITEM_COORDINATE_ROUTE: (state, payload) => {
    state.coordinateRoutes.splice(payload.index, 1);
  },

  REMOVE_ALL_COORDINATE_ROUTE: (state) => {
    state.coordinateRoutes = []
  },

  REMOVE_NODES: (state) => {
    state.nodes = [];
  },

  REMOVE_VECTORS: (state) => {
    state.vectors = [];
  },

  REMOVE_STATIONS: (state) => {
    state.stations = [];
  },

  SET_STOP_ON_ROUTES: (state, payload) => {
    state.stations = Object.values(payload).map(el => Object.values(el)).flat();
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}