<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

// import L from "leaflet/dist/leaflet";

export default {
  data() {
    return {
      
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/main.scss";
</style>
